import { useTranslation } from 'react-i18next';
import { FoxPlanningApi } from 'src/api/foxPlanningApi';
import { QUERY_KEYS, useQueryWithNotifications } from 'src/api/api-utils';
import { useUserAlias } from 'src/hooks/useUserAlias';

/** update this list after adding new feature flags in AppConfig */
type FeatureFlag =
  | 'feShowStage'
  | 'fePlanJanitorial'
  | 'fePlanOtrSupplyType'
  | 'feServerSidePaginationUtrProductivityInput'
  | 'feServerSidePaginationUtrProductivityOutput'
  | 'usingValidationFramework'
  | 'utrUSEastBucket'
  | 'baselineOTRIB'
  | 'baselineOTROB'
  | 'feUTRCsvUploadOnly'
  | 'feBaselineSecurity'
  | 'feS3UrlGeneration'
  | 'feBaselineSecurity'
  | 'feBaselineTopsDownForecast'
  | 'feBaselineCFConsolidation'
  | 'feBaselineJanitorial'
  | 'feDisableAgGridInferringDataTypes';

export type FeatureFlagsResult = Record<FeatureFlag, boolean | undefined> | null | undefined;

export const useFeatureFlags = () => {
  const { alias } = useUserAlias();
  const { t } = useTranslation();

  return useQueryWithNotifications({
    queryKey: [QUERY_KEYS.FEATURE_FLAGS, alias],
    queryFn: async () => {
      const { featureFlagsResult } = await FoxPlanningApi.getFeatureFlags({
        requesterIdentity: alias,
      });

      return featureFlagsResult as FeatureFlagsResult;
    },
    meta: { errorNotificationText: t('api_error_fetch_feature_flags') },
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};
